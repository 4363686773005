import { Card } from '@Azos-Seguros/apollo'
import { css, styled, Utils } from '@azos/shared'

type RootProps = {
  $isWebview: boolean
  $isMonthlyPayment: boolean
}

export const Root = styled(
  Card,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $isWebview, $isMonthlyPayment }) => css`
    position: fixed;
    bottom: ${$isWebview ? '0px' : '80px'};
    left: 0;
    margin: 0;
    z-index: 1199;
    width: 100%;

    @media (min-width: 600px) {
      bottom: 0;
      position: fixed;
      padding-left: 120px;
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      max-width: 295px;
      width: 100%;
      height: fit-content;
      padding: 18px;
      position: inherit;
      background-color: #ffffff;
      margin-top: 80px;
    }

    .coverage-resume {
      &__aside {
        &--accordion-content {
          margin-bottom: ${theme.spacing[4]}px;

          @media (min-width: 600px) {
            margin-top: ${theme.spacing[4]}px;
          }

          @media (min-width: ${theme.breakpoints.md}px) {
            margin-top: ${theme.spacing[5]}px;
          }
        }

        &--description {
          margin: ${theme.spacing[3]}px 0;
          display: flex;
          align-items: center;

          span {
            color: ${theme.palette.neutral[400]};
          }

          i {
            font-size: 1.25rem;
            color: ${theme.palette.neutral[400]};
            margin-right: ${theme.spacing[2]}px;
          }
        }

        &--icon {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          @media (min-width: 600px) {
            margin-bottom: 0;
          }

          span {
            font-weight: 600;
          }

          i {
            font-size: 24px;
            color: #a3aab9;
            margin-right: 12px;
          }
        }

        &--info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: ${$isMonthlyPayment ? theme.spacing[1] : 0}px;

          &__annual-discount {
            display: flex;
            align-items: center;
            gap: ${theme.spacing[2]}px;

            &--discount {
              display: flex;
              align-items: center;
              gap: ${theme.spacing[1]}px;
            }

            &--badge {
              display: flex;
              gap: ${theme.spacing[1]}px;
              align-items: center;
              padding: 0 ${theme.spacing[2]}px;
              background-color: ${$isMonthlyPayment
                ? theme.palette.neutral[200]
                : theme.palette.primary[50]};

              border-radius: 4px;
              width: fit-content;

              > span {
                color: ${$isMonthlyPayment
                  ? theme.palette.neutral[400]
                  : theme.palette.primary[600]};
              }
            }
          }

          &__price {
            display: flex;
            align-items: center;
            gap: ${theme.spacing[1]}px;

            @media (min-width: 600px) {
              margin-bottom: 0;
            }
          }
        }

        &--coverage-item {
          &:not(:first-of-type) {
            margin-top: ${theme.spacing[3]}px;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;

          &-value {
            margin-right: ${theme.spacing[4]}px;
          }

          &-description {
            display: block;
            color: ${theme.palette.neutral[400]};
            white-space: nowrap;
          }
        }

        button {
          @media (min-width: ${theme.breakpoints.md}px) {
            margin-top: 32px;
          }

          padding: 12px;
          margin: 12px 0 0 0;
        }
      }
    }
  `,
)
