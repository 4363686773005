import React from 'react'

import { CardAccordion, Typography } from '@Azos-Seguros/apollo'
import { CheckoutPaymentFrequency } from '@azos/core'
import { useMediaQuery, Utils } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { DiscountByProposal } from '@domain/models'

import { CoverageResumeProps } from './CoverageResume.props'
import { Root } from './CoverageResume.styles'

const CoverageResume: React.VFC<CoverageResumeProps> = ({
  total,
  coverages,
  onButtonClick,
  isButtonDisabled,
  onExpand,
  className,
  showButton = true,
  isWebview = false,
  annualPremium,
  paymentFrequency,
  buttonText = 'Definir cobertura',
  discount,
  allDiscounts,
}) => {
  const { isMobile } = useMediaQuery()

  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false)

  const isMonthlyPayment = paymentFrequency === CheckoutPaymentFrequency.MONTHLY

  React.useEffect(() => {
    setIsDetailsExpanded(!isMobile)
  }, [isMobile])

  const availableDiscount =
    discount?.paymentFrequency === paymentFrequency ? discount : undefined

  const periodPrice =
    availableDiscount?.discountPrice ||
    (paymentFrequency === CheckoutPaymentFrequency.MONTHLY
      ? total
      : (annualPremium as number))

  const differentFrequencyDiscount = allDiscounts?.find(
    disc => disc.paymentFrequency !== paymentFrequency,
  )

  const handleDetailsExpanded = () => {
    if (!isDetailsExpanded) {
      onExpand?.()
    }

    setIsDetailsExpanded(open => !open)
  }

  const getDiscountBadgeText = (
    actualDiscount?: DiscountByProposal,
    diffFrequencyDiscount?: DiscountByProposal,
  ) => {
    if (actualDiscount) return `Economize ${actualDiscount.percentage}%`

    if (diffFrequencyDiscount) {
      const paymentCases: Record<CheckoutPaymentFrequency, string> = {
        [CheckoutPaymentFrequency.MONTHLY]: 'mensal',
        [CheckoutPaymentFrequency.ANNUAL]: 'anual',
      }

      return `Economize ${diffFrequencyDiscount.percentage}% no pagamento ${
        paymentCases[diffFrequencyDiscount.paymentFrequency]
      }`
    }
  }

  return (
    <Root
      className={className}
      $isWebview={isWebview}
      $isMonthlyPayment={isMonthlyPayment}
    >
      <div
        onClick={() => {
          if (isMobile) handleDetailsExpanded()
        }}
      >
        {isDetailsExpanded && (
          <div className="coverage-resume__aside--icon">
            <i className="icon-receipt" />
            <Typography variant="bodyBold">Resumo do pedido</Typography>
          </div>
        )}

        <CardAccordion open={isDetailsExpanded}>
          <div className="coverage-resume__aside--accordion-content">
            {coverages.map(coverage => (
              <div
                key={coverage.code}
                className="coverage-resume__aside--coverage-item"
              >
                <div className="coverage-resume__aside--coverage-item-value">
                  <Typography variant="bodyBold3">
                    {coverage.info.name}
                  </Typography>
                  <Typography
                    className="coverage-resume__aside--coverage-item-description"
                    variant="body3"
                  >
                    {Utils.currency.formatWithoutCents(
                      coverage.data.premiumValue,
                    )}
                  </Typography>
                </div>
                <Typography
                  variant="body3"
                  className="coverage-resume__aside--coverage-item-description"
                >
                  + {Utils.currency.format(coverage.data.price)}
                </Typography>
              </div>
            ))}
          </div>
        </CardAccordion>

        <div className="coverage-resume__aside--info">
          <div className="coverage-resume__aside--info__annual-discount">
            {availableDiscount && (
              <div className="coverage-resume__aside--info__annual-discount--discount">
                <Typography tag="del" variant="body3" color="lighter">
                  R$
                </Typography>

                <Typography tag="del" variant="body" color="light">
                  {Utils.currency.format(
                    availableDiscount.originalPrice ?? 0,
                    '',
                  )}
                </Typography>
              </div>
            )}

            {allDiscounts &&
              (!!availableDiscount || !!differentFrequencyDiscount) && (
                <div className="coverage-resume__aside--info__annual-discount--badge">
                  <Typography
                    tag="span"
                    variant="body3"
                    className="icon-money-16"
                  />{' '}
                  <Typography variant="captionBold">
                    {getDiscountBadgeText(
                      availableDiscount,
                      differentFrequencyDiscount,
                    )}
                  </Typography>
                </div>
              )}
          </div>

          <div className="coverage-resume__aside--info__price">
            <Typography tag="p" variant="body3" color="lighter">
              R$
            </Typography>
            <Typography tag="span" variant="h5">
              {Utils.currency.format(periodPrice ?? 0, '')}
            </Typography>
            <Typography variant="body3">
              {' '}
              por <strong>{isMonthlyPayment ? 'mês' : 'ano'} </strong>
              (prêmio)
            </Typography>
          </div>
        </div>

        {coverages.length === 0 && isMobile && (
          <div className="coverage-resume__aside--description">
            <i className="icon-policy-doubt" />
            <Typography variant="body3">
              {' '}
              Selecione uma cobertura para calcular...
            </Typography>
          </div>
        )}

        {!isDetailsExpanded && coverages.length > 0 && (
          <div className="coverage-resume__aside--description">
            <i className="icon-add" />
            <Typography variant="body3"> Mais detalhes do pedido</Typography>
          </div>
        )}
      </div>
      {showButton && (
        <Button disabled={isButtonDisabled} onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </Root>
  )
}

export default CoverageResume
