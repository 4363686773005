import { css, styled } from '@azos/shared'
import { Button as SharedButton } from '@azos/shared/src/components/v2'

export const Button = styled(SharedButton)(
  ({ theme }) => css`
    padding: 4px;
    margin-top: 30px;

    @media (min-width: 600px) {
      margin-top: 20px;
    }

    i {
      color: ${theme.palette.neutral[300]} !important;
      font-size: 1.25em !important;
    }

    span {
      color: ${theme.palette.neutral[300]} !important;

      > * + * {
        margin-left: 8px;
      }
    }
  `,
)

Button.defaultProps = {
  variant: 'tertiary',
}
