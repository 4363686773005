import { css, styled } from '@azos/shared'
import { Form as FormikForm } from 'formik'

export const Form = styled(FormikForm)(
  ({ theme }) => css`
    overflow-y: visible;

    .upsell-wizard {
      &__back-button {
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__avatar-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          color: ${theme.palette.neutral[300]};
          font-size: 1.5rem;
        }
      }

      &__avatar-card {
        .chat-avatar-card {
          margin: auto;
          margin-bottom: ${theme.spacing[3]}px;
        }
      }
      &__form-content {
        width: 100%;
        max-width: 440px;
        padding: ${theme.spacing[4]}px;
        background: ${theme.palette.neutral[50]};
      }
      &__control-container {
        margin-top: 46px;
      }
    }
  `,
)
