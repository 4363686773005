import React from 'react'

import { useStep } from '@Azos-Seguros/apollo'

import { WizardItemConfig } from '../Wizard.props'
import { WizardContextData, WizardProviderProps } from './WizardProvider.props'

const WizardContext = React.createContext({} as WizardContextData)

const defaultWizardItemConfig: WizardItemConfig = {
  hideButton: false,
  disableControls: false,
  disabledButton: false,
  requiredValid: true,
  scrollable: false,
}

export const WizardProvider: React.FCC<WizardProviderProps> = ({
  children,
  //
  step = 0,
  max,
  //
  onChange,
}) => {
  const [currentStep, helpers] = useStep(max)

  const {
    canGoToPrevStep,
    canGoToNextStep,
    goToNextStep,
    goToPrevStep,
    setStep,
  } = helpers

  const [currentConfig, setCurrentConfig] = React.useState<WizardItemConfig>(
    defaultWizardItemConfig,
  )

  const isFirstStep = React.useMemo<boolean>(
    () => currentStep === 1 && !canGoToPrevStep,
    [currentStep, canGoToPrevStep],
  )

  const isLastStep = React.useMemo<boolean>(
    () => currentStep === max && !canGoToNextStep,
    [max, currentStep, canGoToNextStep],
  )

  React.useEffect(() => {
    onChange?.(currentStep)
  }, [currentStep, onChange])

  const value: WizardContextData = {
    step: currentStep,
    max,
    //
    isFirstStep,
    isLastStep,
    //
    setStep,
    setConfig: (data: Partial<WizardItemConfig>) =>
      setCurrentConfig(Object.assign({}, defaultWizardItemConfig, data)),
    stepConfig: currentConfig,
    //
    prevStep: goToPrevStep,
    nextStep: goToNextStep,
  }

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  )
}

export const useWizard = () => {
  const context = React.useContext(WizardContext)

  if (!context) {
    throw new Error('The useWizard should within WizardProvider')
  }

  return context
}
