import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { DPSQuestionViewModel } from '@azos/core'
import {
  ListOptions,
  ListOptionsSource,
  ListOptionsValue,
  ListOptionType,
} from '@azos/shared/src/components/v2'
import { DPSValue } from '@presentation/pages/dps/dps.props'
import { useFormikContext } from 'formik'

import { DynamicQuestionContentProps } from './DynamicQuestionContent.props'

const getListOptionType = (question: DPSQuestionViewModel) => {
  const { choices, type } = question

  if (type === 'single_choice' && choices.length >= 3) {
    return ListOptionType.RADIO_MULTIPLE
  }

  return type
}
const formatSource = (question: DPSQuestionViewModel): ListOptionsSource => {
  return question.choices.map(choice => ({
    label: choice.text,
    value: choice.id,
  }))
}

const formatData = (value: string | string[] | null): ListOptionsValue[] =>
  !!value ? (Array.isArray(value) ? value : [value]) : []

interface QuestionValue {
  value?: string | string[] | null
}

const DynamicQuestionContent: React.FCC<DynamicQuestionContentProps> = ({
  question,
  handleChange,
  showQuestionTitle = true,
}) => {
  const { values, errors, isSubmitting, touched } = useFormikContext<DPSValue>()

  return (
    <div>
      {!!question.subtitle && (
        <Typography variant="h6" className="dynamic-question__description">
          {question.subtitle}
        </Typography>
      )}

      {question.description && (
        <Typography
          variant="body2"
          color="light"
          className="dynamic-question__description"
          dangerouslySetInnerHTML={{ __html: question.description }}
        />
      )}

      <ListOptions
        name={question.questionId}
        type={getListOptionType(question)}
        options={formatSource(question)}
        value={formatData(values[question.questionId]?.value)}
        onChange={handleChange}
        error={
          touched[question.questionId] &&
          !!(errors[question.questionId] as QuestionValue)?.value
        }
        helperText={(
          errors[question.questionId] as QuestionValue
        )?.value?.toString()}
        disabled={isSubmitting}
      />
    </div>
  )
}

export default DynamicQuestionContent
