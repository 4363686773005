import React from 'react'
import { animated, Transition } from 'react-spring'

import { useWizard } from '../../providers'

type WizardProps = {
  children: React.ReactNode[]
}

const WizardSwipper: React.FCC<WizardProps> = ({ children }) => {
  const { step: currentStep } = useWizard()

  const elements = React.useMemo(() => {
    const childrens = Array.isArray(children) ? children : [children]
    return childrens[currentStep - 1] || null
  }, [currentStep, children])

  const elementRender = React.useMemo(() => elements, [elements])

  return <div>{elementRender}</div>
}

export default WizardSwipper
