import React from 'react'

import { useWizard } from '../../providers'
import { WizardItemProps } from '../../Wizard.props'

const WizardItem = ({
  component: ItemComponent,
  config = {},
}: WizardItemProps) => {
  const context = useWizard()

  React.useEffect(() => {
    context.setConfig(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  return <ItemComponent context={context} />
}

export default WizardItem
