import React from 'react'

import { Button } from '@azos/shared/src/components/v2'
import { useFormikContext } from 'formik'

import { ControlProps } from './Control.props'
import { Root } from './Control.styles'

const Control: React.FCC<ControlProps> = ({
  disabled = false,
  disabledButton = false,
  requiredValid = true,
  //
  textButton = 'Continuar',
  //
  ...rest
}) => {
  const { isValid: formIsValid, isSubmitting } = useFormikContext<any>()

  const isAllDisabled = React.useMemo<boolean>(
    () => disabled || isSubmitting,
    [disabled, isSubmitting],
  )

  const isDisabledNextButton = React.useMemo(() => {
    const isRequiredValid = requiredValid && !formIsValid
    return disabledButton || isRequiredValid || isAllDisabled
  }, [disabledButton, formIsValid, isAllDisabled, requiredValid])

  return (
    <Root $disabled={disabled} {...rest}>
      <Button
        color="primary"
        type="submit"
        disabled={isDisabledNextButton}
        fullWidth
      >
        {textButton}
      </Button>
    </Root>
  )
}

export default Control
