import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ChatAvatarCard, useMediaQuery } from '@azos/shared'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import { Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'

import { BackButton } from '../../../BackButton'
import { useWizard } from '../../providers'
import { Control } from './components'
import { FormProps as MyFormProps } from './Form.props'
import { Form } from './Form.styles'

type FormValues = any
type Props = MyFormProps<FormValues>

const MyForm: React.FCC<Props> = ({
  children,
  initialValues,
  onSubmit: finishSubmit,
}) => {
  const { prevStep, nextStep, isLastStep, stepConfig, isFirstStep } =
    useWizard()
  const router = useRouter()
  const { specialistUpsell } = useUpsell()

  const { isMobile } = useMediaQuery()

  const isScrollable = React.useMemo<boolean>(
    () => stepConfig.scrollable && isMobile,
    [isMobile, stepConfig.scrollable],
  )
  const disableControls = React.useMemo<boolean>(
    () => stepConfig.disableControls || isScrollable,
    [isScrollable, stepConfig.disableControls],
  )

  const disabledButton = React.useMemo<boolean>(
    () => stepConfig.disabledButton,
    [stepConfig.disabledButton],
  )

  const hideButton = React.useMemo<boolean>(
    () => stepConfig.hideButton || false,
    [stepConfig.hideButton],
  )

  const requiredValid = React.useMemo<boolean>(
    () => stepConfig.requiredValid || false,
    [stepConfig.requiredValid],
  )

  const validationSchema = React.useMemo(
    () => stepConfig.schema || null,
    [stepConfig.schema],
  )

  const handlePrevStep = () => {
    analytics.dashboard.upsell.DPS.goBack.execute()

    if (isFirstStep) {
      return router.push('/simular-novo-seguro/calculadora')
    }

    prevStep()
  }

  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<any>) => {
      await stepConfig.onSubmit?.(values)

      if (isLastStep) {
        await finishSubmit?.(values)
        return
      }

      helpers.setTouched({})
      nextStep()
    },
    [finishSubmit, isLastStep, nextStep, stepConfig],
  )

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <BackButton
          onClick={handlePrevStep}
          className="upsell-wizard__back-button"
        />
        {isFirstStep && (
          <ChatAvatarCard
            title={
              <div className="upsell-wizard__avatar-title">
                <Typography variant="bodyBold">Continuando,</Typography>
                <i className="icon-help" />
              </div>
            }
            description={
              <>
                marque a seguir se teve alguma mudança no seu estilo de vida e
                saúde. <br /> <br /> Você pode selecionar mais de uma, se for
                esse o caso.
              </>
            }
            specialist={specialistUpsell}
            className="upsell-wizard__avatar-card"
          />
        )}
        <div className="upsell-wizard__form-content">
          {children}
          {((!disableControls && !hideButton) || isLastStep) && (
            <div className="upsell-wizard__control-container">
              <Control
                disabledButton={disabledButton}
                requiredValid={requiredValid}
              />
            </div>
          )}
        </div>
      </Form>
    </Formik>
  )
}

export default MyForm
