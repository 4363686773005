import React from 'react'

import { Button, Text } from '@azos/shared/src/components/v2'
import Router from 'next/router'

import { ErrorProps } from './Error.props'
import { Root } from './Error.styles'

const Error = ({
  title = 'Opss!',
  description = 'Houve um problema, por favor tente novamente.',
  buttonText = 'Ir para página inicial',
  onClick = () => Router.push('/'),
}: ErrorProps) => {
  return (
    <Root>
      <div>
        <Text variant="h3">{title}</Text>
        <Text variant="body2" className="description">
          {description}
        </Text>
        <Button
          color="outline"
          type="submit"
          title={buttonText}
          onClick={onClick}
          fullWidth
        >
          {buttonText}
        </Button>
      </div>
    </Root>
  )
}

export default Error
