import { css, styled, Utils } from '@azos/shared'

type RootProps = {
  $disabled: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $disabled }) =>
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      transition: opacity 0.4s;
      opacity: ${$disabled ? 0.4 : 1};
      width: 100%;

      button.control__back-button {
        width: 48px;
      }

      > button {
        height: 100%;
      }
      > button + button {
        margin-left: ${theme.spacing[2]}px;
      }
    `,
)
