import { styled, css } from '@azos/shared'

export const Root = styled('div')(
  () => css`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      & > .description,
      & > button {
        margin-top: 1rem;
      }
    }
  `,
)
