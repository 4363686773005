import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { useRouter } from 'next/router'

import { BackButtonProps } from './BackButton.props'
import { Button } from './BackButton.styles'

const BackButton: React.VFC<BackButtonProps> = ({
  onClick,
  className,
  text = 'Voltar',
}) => {
  const router = useRouter()

  const handleClick = () => {
    if (onClick) {
      return onClick()
    }
    router.back()
  }

  return (
    <Button
      onClick={handleClick}
      type="button"
      className={className}
      icon="icon-arrow-left"
    >
      {text}
    </Button>
  )
}

export default BackButton
