import React from 'react'

import Router from 'next/router'

import { Error } from '../../molecules/Error'
import { ErrorStateProps } from './ErrorState.props'

const ErrorState: React.FCC<ErrorStateProps> = ({ children, isError }) => {
  return (
    <>
      {isError ? (
        <Error buttonText="Tentar novamente" onClick={() => Router.reload()} />
      ) : (
        children
      )}
    </>
  )
}

export default ErrorState
